/*
* Base
*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  height: 64px;
  max-width: 100vw;
  background: var(--header-nav-background-color);
  &.isBurgerOpen {
    height: 100vh;
    @media (--tablet-l) {
      height: auto;
    }
  }

  @media (--tablet) {
    height: 72px;
  }

  @media (--tablet-l) {
    height: auto;
    overflow: visible;
  }

  @media (--desktop) {
    background: var(--header-nav-background-color);
  }
}

.headerInner {
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  width: 100%;
  background: var(--header-nav-background-color);

  & > div {
    width: 100%;
  }

  @media (--tablet) {
    padding-top: var(--spacing-5);
    padding-bottom: var(--spacing-5);
  }

  @media (--tablet-l) {
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
  }
}

.colLeft {
  display: flex;
  gap: var(--spacing-12);
  align-items: center;
  @media (--tablet-l) {
    height: 48px;
  }
}

.colRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (--tablet-l) {
    gap: var(--spacing-4);
  }

  @media (--desktop) {
    gap: var(--spacing-1);
  }
}

.logo {
  display: inline-block;
  height: 32px;

  & > svg {
    width: auto;
    height: 32px;
  }

  @media (--desktop) {
    margin-left: var(--spacing-3);
  }
}

.searchWrapper {
  /* display: none; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  & .closeSearch {
    display: none;
    position: absolute;
    top: var(--spacing-5);
    right: var(--spacing-4);
    border: 0;
    background: 0;
    padding: 0;
  }

  &.active {
    pointer-events: all;
    display: block;
    opacity: 1;
    visibility: visible;

    & .closeSearch {
      display: block;
    }
  }

  @media (--tablet-l) {
    display: block;
    position: static;

    & .closeSearch {
      display: none;
    }
  }
}

.faves {
  position: relative;
  &:hover {
    & > svg > path {
      fill: var(--header-nav-fav-icon-bg);
    }
  }
  & .counter {
    display: block;
    position: absolute;
    top: 0;
    left: 19px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--color-primary-700);

    background: var(--header-nav-counter-bg);
    text-align: center;
    line-height: 14px;
    color: var(--color-neutral-700);
    opacity: 0;
    transform: translateY(5px);
    transition: transform 0.25s ease, opacity 0.25s ease;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    @media (--tablet-l) {
      top: 11px;
      left: 14px;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-3);
  background: none;
  border: none;
  color: var(--header-nav-item-color);
  text-decoration: none;
  cursor: pointer;

  & > span {
    display: none;
    margin-left: var(--spacing-2);
  }

  &:last-child {
    padding-right: 0;
  }

  &.horizontalNavOnly,
  &.desktopOnly {
    display: none;
  }

  /* Dropdown nav starts here */
  @media (--tablet-l) {
    padding: 0 var(--spacing-2);
    height: var(--spacing-12);

    &:last-of-type {
      padding-right: var(--spacing-2);
    }

    &:hover,
    &:focus,
    &.focused {
      background-color: var(--header-nav-item-hover-bg);
    }

    &:active {
      background-color: var(--header-nav-item-active-bg);
    }

    &.horizontalNavOnly {
      display: flex;
    }

    &.notHorizontalNav {
      display: none;
    }
  }

  @media (--desktop) {
    &:last-of-type {
      padding-right: var(--spacing-3);
    }

    &.desktopOnly {
      display: flex;
    }

    & > span {
      display: inline;
    }

    &.asideItem {
      position: relative;
      margin-left: var(--spacing-3);
      padding-left: var(--spacing-2);

      &:before {
        content: '';
        position: absolute;
        left: -8px;
        height: var(--spacing-12);
        border-left: solid 2px var(--color-primary-600);
      }
    }
  }
}

.langWrapper {
  position: relative;

  @media (--tablet-l) {
    & .item {
      & > span {
        display: inline;
      }
    }
  }

  @media (--desktop) {
    order: 0;
  }
}

.navWrapper {
  width: 100%;

  & nav {
    transition: transform 300ms ease-in-out 0s;
    transform: translateX(110vw);
  }

  &:global(.navOpen) nav {
    transform: translateX(0);
  }

  /* Dropdown nav starts here */
  @media (--tablet-l) {
    & nav {
      transition: none;
      transform: translateX(0) !important;
    }
  }
}
