.statusDisplayWrapper {
  width: 100%;
  @media (--tablet-l) {
    width: 800px;
  }
}

.statusDisplayBodyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;

  @media (--tablet-l) {
    display: block;
  }
}

.returnActionCta {
  text-align: center;
}

.resultSubhead,
.resultDescription {
  text-align: center;

  &.leftAlign {
    text-align: left;
    width: 100%;
  }
}

.resultDescription,
.resultSubhead,
.resultHeader {
  padding-top: var(--spacing-5);
  && {
    margin: 0;
  }
}

.resultSubhead + .resultDescription {
  padding-top: 0;
}

.resultMessageWrapper {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-8);
  place-items: center;
}
