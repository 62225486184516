.filtersCol {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  min-height: 100vh;
  /* iOS viewport bug fix */
  height: 100dvh;
  background: var(--color-overlay);
  visibility: hidden;
  opacity: 0;
  transform: translateX(-110vw);
  transition: opacity 0.2s ease;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (--tablet) {
    height: auto;
    min-height: auto;
  }

  &.isOpen {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);

    & .filtersWrapper {
      transform: translateX(0);
    }
  }

  @media (--desktop) {
    position: static;
    visibility: visible;
    z-index: 0;
    opacity: 1;
    transform: translateX(0);
    background: #fff;
  }

  & [data-ad] {
    display: none;
    @media (--desktop) {
      display: block;
    }
  }
}

.filtersBottom {
  height: 70vh;
  border-bottom: solid 1px var(--color-neutral-100);
  display: flex;
  flex-direction: column;
}

.loadingFilters {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--color-neutral-100);
  margin-top: 29px;
}

.filtersWrapper {
  overflow: scroll;
  height: 100vh;
  /* iOS viewport bug fix */
  height: 100dvh;
  transform: translateX(-110vw);
  transition: transform 0.3s ease 0.1s;
  background: #fff;
  @media (--tablet) {
    max-width: 360px;
  }
  @media (--desktop) {
    overflow: visible;
    margin-bottom: var(--spacing-6);
    max-width: none;
    border: solid 1px var(--color-neutral-100);
    border-bottom: none;
    transform: none;
    transition: none;
    height: auto;
    min-height: auto;
    transition-behavior: allow-discrete;
    interpolate-size: allow-keywords;

    & .secondaryFilter {
      display: none;
      transition: 0.2s;
      height: auto;

      @starting-style {
        opacity: 0;
        height: 0;
      }
    }

    &.isShowingAllFilters {
      & .secondaryFilter {
        display: block;
      }
    }
  }
}

.filtersHeader {
  position: sticky;
  top: 0;
  z-index: 4;
  border-bottom: solid 1px var(--color-neutral-50);
  background: var(--color-championship-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-5);

  & h2 {
    margin: 0;
  }

  & .closeBtn {
    padding: var(--spacing-2);
    height: var(--spacing-10);
  }

  & .clearAllBtn {
    display: none;
  }

  @media (--desktop) {
    padding: var(--spacing-5);
    position: inherit;
    border-bottom: none;
    & .closeBtn {
      display: none;
    }

    & .clearAllBtn {
      display: block;
    }
  }
}

.filtersContent {
  height: auto;
  min-height: 100vh;
  @media (--desktop) {
    min-height: fit-content;
  }
}

.filterAccord {
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);

  padding-bottom: 0;
  &[data-expanded='true'] {
    overflow: visible;
  }

  &.searching {
    @media (--mobile) {
      position: absolute;
      top: 58px;
      z-index: 10;
    }
  }
  &&& {
    padding-bottom: 0;
    border-color: var(--color-neutral-100);
  }

  & div[data-accordion-component='AccordionItemButton'] {
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);

    &[aria-expanded='true'] {
      font-weight: 700;
    }
  }

  & div[data-accordion-component='AccordionItemPanel'] {
    width: 100%;
  }
}

.mobileCtas {
  position: sticky;
  bottom: 0;
  z-index: 4;
  display: flex;
  background: var(--color-championship-white);
  box-shadow: var(--shadow-xxl);

  justify-content: space-between;
  gap: var(--spacing-2);
  padding: var(--spacing-3) var(--spacing-5);

  & .searchBtnMob {
    flex-grow: 1;
    white-space: normal;
  }

  @media (--desktop) {
    display: none;
  }
}

button.viewMoreBtn {
  display: none;
  margin-bottom: var(--spacing-6);
  width: 100%;

  @media (--desktop) {
    display: inline-flex;
  }
}
