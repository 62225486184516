.priceFilter {
  padding: var(--spacing-2) 0 var(--spacing-4);

  & .discountSwitch {
    margin-bottom: var(--spacing-4);
    padding: 0;

    & label {
      margin: 0;
      padding: var(--spacing-2) 0;
    }
  }

  & .priceTypeBtns {
    margin-bottom: var(--spacing-6);
    width: 100%;
  }

  & .minMaxInputs {
    display: flex;
    gap: var(--spacing-2);
  }

  & .auxInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-5);

    & .resultCount {
      color: var(--color-neutral-500);
    }

    & button {
      padding-right: 0;
    }
  }

  & .financeProduct {
    margin-bottom: var(--spacing-5);
  }

  & .listItem {
    gap: var(--spacing-2);
    padding: 0;
  }
}
