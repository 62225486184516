/*
* Base
*/

.navItem {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-2);
  padding: 0;
  width: 100%;
  height: 40px;
  border: 0;
  background: #fff;
  text-decoration: none;
  color: var(--color-neutral-700);
  transition: color 0.3s ease, background 0.3s ease;

  & > span {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    line-height: normal;
  }

  &.isActive {
    & > svg {
      transform: rotate(180deg);
    }
  }
}

.nav {
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh - 64px);
  background: #fff;

  & ul {
    margin: 0 auto;
    padding: var(--spacing-3) 0;
    list-style: none;
  }

  & > ul {
    padding: var(--spacing-3) var(--spacing-5) var(--spacing-2);
  }

  & li.headerItems {
    padding-top: var(--spacing-3);
    border-top: solid 1px var(--color-neutral-100);

    &:last-of-type {
      padding-top: 0;
      border-top: none;
    }
  }

  & :global(.lastNavItem) {
    padding-bottom: var(--spacing-3);
  }

  /* Dropdown nav starts here */
  @media (--tablet-l) {
    display: none;
  }
}

.auxDetails {
  margin: 0 var(--spacing-5);
}

.auxContact {
  line-height: 18px;
  margin: 0;
  padding: var(--spacing-2) 0;

  & > span {
    display: inline;
    margin-right: var(--spacing-1);
  }

  & > a {
    color: var(--color-neutral-700);
    text-decoration: none;
  }

  &:first-of-type {
    padding-top: var(--spacing-6);
    border-top: solid 1px var(--color-neutral-100);
  }

  &:last-of-type {
    padding-bottom: var(--spacing-6);
  }
}

.appDetails {
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--spacing-4);
  border-top: solid 1px var(--color-neutral-100);

  & > span {
    display: block;
    width: 100%;
    margin-bottom: var(--spacing-4);
  }

  & > a {
    width: auto;
    height: 38px;
  }
}
