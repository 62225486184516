.wrapper {
  display: inline-block;
  width: var(--spacing-6);
  height: var(--spacing-6);
  padding: 2px;
  position: relative;
}

.radio {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  -webkit-appearance: none;
  width: var(--spacing-5);
  height: var(--spacing-5);
  margin: 0;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.fakeRadio {
  position: relative;
  display: inline-block;
  height: var(--spacing-5);
  width: var(--spacing-5);
  border: 2px solid var(--color-neutral-500);
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 0.2s;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--spacing-3);
    height: var(--spacing-3);
    border-radius: 50%;
    background-color: var(--color-neutral-700);
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.wrapper:hover > .fakeRadio {
  border-color: var(--color-neutral-700);
}

.wrapper:hover > input:disabled + .fakeRadio {
  border-color: var(--color-neutral-300);
}

input:checked + .fakeRadio {
  border-color: var(--color-neutral-700);

  &:before {
    opacity: 1;
  }
}

input:disabled + .fakeRadio {
  border-color: var(--color-neutral-300) !important;
  cursor: not-allowed;

  &:before {
    background-color: var(--color-neutral-300);
  }
}

.error > .fakeRadio,
.error > input:checked + .fakeRadio {
  border-color: var(--color-error-700);
}
