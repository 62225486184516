.autoCallbackChkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.error {
    border: 1px solid var(--form-control-error-color);
    padding: 5px;
  }
  && h6 {
    margin: 0;
  }
  && label {
    display: flex;
    align-items: center;
    span {
      padding-left: 10px;
    }
  }
  .errorMessage {
    color: var(--form-control-error-color);
    font-size: 12px;
  }
}
