.toggleWrapper {
  position: relative;
  padding: 0 20px;
  border-bottom: 1px solid var(--color-neutral-100);
}

.tooltip {
  position: absolute !important;
  right: 100px;
}

.tooltipText {
  @media (--mobile) {
    transform: translate(-80%, -100%) !important;
  }
}
