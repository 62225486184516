.plpWrapper {
  padding-top: 0;
  width: 100%;

  @media (--desktop) {
    padding-top: var(--spacing-3);
    gap: var(--spacing-4);
  }
  & > section {
    @media (--desktop-s) {
      max-width: 888px;
    }

    @media (--desktop-l) {
      max-width: 960px;
    }
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.imageCampaignBanner {
  display: none;
  height: 70px;
  position: relative;

  @media (--desktop) {
    display: block;
  }
}
.imageCampaignBannerMobile {
  display: block;
  height: 50px;
  position: relative;
  margin-top: var(--spacing-4);

  @media (--desktop) {
    display: none;
  }
}

.filters {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-width: 0;
  min-width: 0;
  @media (--desktop) {
    flex: 1 1 310px;
    max-width: 310px;
  }
}

.listings {
  flex: 1 1 980px;
  max-width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  min-width: 0;

  @media (--desktop-s) {
    flex: 1;
  }
}

.plpDesktopAds {
  display: none;

  &:empty {
    display: none;
  }

  @media (--tablet) {
    display: block;
  }
}

.plpMobileAds {
  display: block;
  background-repeat: repeat;
  background-size: 35%;

  &:empty {
    display: none;
  }

  &.forceOpen {
    min-height: 250px;
  }

  @media (--tablet) {
    display: none;
  }
}

.plpMobileCarfinance {
  width: 100%;
  order: 2;
}

.plpMainBillboardAd {
  width: 100%;
  order: 1;

  &.notInInitVP {
    order: 2;
  }

  @media (min-width: 550px) {
    order: 2;
  }

  @media (--mobile) {
    &:has(.plpMobileAds:empty) {
      display: none;
    }
  }

  @media (--tablet) {
    &:has(.plpDesktopAds:empty) {
      display: none;
    }
  }

  @media (--tablet-l) {
    order: 3;

    &.notInInitVP {
      order: 3;
    }
  }
}

.plpSecondaryBillboardAd {
  width: 100%;
  order: 3;

  @media (min-width: 550px) {
    order: 6;
  }

  @media (--mobile) {
    &:has(.plpMobileAds:empty) {
      display: none;
    }
  }

  @media (--tablet) {
    &:has(.plpDesktopAds:empty) {
      display: none;
    }
  }

  @media (--tablet-l) {
    order: 12;
  }
}

.tilesWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-between;
  gap: var(--spacing-3);

  & > article {
    flex: 1 1 100%;
    max-width: calc(100%);
  }

  @media (min-width: 630px) {
    & > article {
      flex: 1 1 calc(50% - 6px);
      max-width: calc(50% - 6px);
    }
  }

  @media (--tablet-l) {
    gap: var(--spacing-2);

    & > article {
      flex: 1 1 calc(33% - 14px);

      max-width: calc(33% - 13px);
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-3) 0 var(--spacing-3) 0;

  @media (--tablet) {
    padding: var(--spacing-6) 0 var(--spacing-3) 0;
  }

  h1 {
    @media (--mobile) {
      margin: 0;
    }
  }
}

.viewMore {
  padding: 0 0 var(--spacing-6) 0;
}

.headerIcons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 35%;

  @media (--desktop) {
    display: none;
  }
}

.title {
  && {
    display: flex;
    align-items: center;
    flex: 1 1 35%;
    flex-direction: row;
    margin-right: var(--spacing-2);
  }
}

.plpMobileBannerAd {
  display: block;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 50;
  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }

  [data-service-consumer='native'] & {
    bottom: 0;
  }
  @media (min-width: 776px) {
    display: none;
  }

  & > div {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: 8px 0;
    height: 66px;
    background: white;
  }
  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }

  [data-service-consumer='native'] & {
    background: white;
    opacity: 0;
    bottom: 0;
    transition: 0.6s;
    transition-delay: 0.01s;

    &:has(iframe) {
      height: 66px;
      opacity: 1;
    }
  }
}

.plpLeaderboardAd {
  display: flex;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 50;

  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }

  @media (--tablet-l) {
    display: none;
  }

  > div {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: 12px 0;
    background: white;
  }

  [data-service-consumer='native'] & {
    height: 66px !important;
    background: white;
    opacity: 0;

    transition: 0.6s;
    transition-delay: 0.1s;

    &:has(iframe) {
      opacity: 1;
    }
  }
}

.seoComponents {
  content-visibility: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-10) 0;
  padding-top: var(--spacing-10);
  height: inherit;
  border-top: solid 1px var(--color-neutral-300);
  width: 100%;

  @media (--tablet) {
    gap: 80px 0;
    margin-top: var(--spacing-10);
    padding-top: var(--spacing-10);
  }

  @media (--tablet-l) {
    padding-top: var(--spacing-20);

    &.hasPopularBrands {
      padding-top: var(--spacing-12);
    }
  }

  & h2 {
    margin-top: 0;
    margin-bottom: var(--spacing-5);

    @media (--tablet) {
      margin-bottom: var(--spacing-10);
    }
  }
}

.faqSection {
  border-top: solid 1px var(--color-neutral-300);
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  margin-top: var(--spacing-6);
  padding-top: var(--spacing-10);
  padding-bottom: 80px;

  @media (--tablet) {
    margin-top: var(--spacing-10);
    padding-top: var(--spacing-10);
  }

  @media (--tablet-l) {
    grid-auto-flow: column;
    padding-top: var(--spacing-20);
  }
}

.faqTitle {
  margin-top: 0;
  margin-bottom: var(--spacing-5);
  max-width: 365px;

  @media (--tablet) {
    margin-bottom: var(--spacing-6);
  }
}

.faqWrapper {
  display: flex;
  flex-direction: column;
}

.faqGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px var(--color-neutral-300);
}

.faqGroup a {
  text-decoration: none;
  color: var(--button-primary-base-bg-color);
}

.faqQuestion {
  max-width: 70%;
}

.externalAdsPanel {
  display: none;

  @media (--desktop) {
    display: block;
  }
}

.seoContent {
  margin-bottom: var(--spacing-10);
}

.legalTextWrapper {
  position: sticky;
  bottom: 0;
  width: 90%;
  padding-left: var(--spacing-10);
  content-visibility: auto;

  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }

  p {
    margin: 0;
  }

  @media (--mobile) {
    width: auto;
    padding: 0;
    z-index: 52;
  }

  @media (--tablet) {
    padding-bottom: var(--spacing-4);
    z-index: 52;
  }

  @media (--desktop-s) {
    padding: var(--spacing-10) 0 var(--spacing-10) var(--spacing-10);
    width: 96%;
  }
}
.breadcrumbs {
  margin: var(--spacing-6) 0;
}

.paginationBreadcrumbs {
  margin: 0 auto var(--spacing-10);
}

.dealersCtaSection {
  display: block;

  @media (--tablet) {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & h5 {
    margin: 0;
    padding: 0;
  }
}

.campaignFaqsSection {
  padding-top: var(--spacing-4);
  margin-bottom: var(--spacing-10);
  width: 100%;
  border-top: solid 1px var(--color-neutral-300);

  @media (--tablet) {
    margin-top: var(--spacing-10);
  }

  @media (--desktop) {
    padding-top: var(--spacing-6);
  }
}

.adPlaceholder {
  height: 250px;
  width: 100%;
  background: blue;

  order: 0;

  @media (min-width: 550px) {
    order: 1;
  }

  @media (min-width: 1024px) {
    order: 2;
  }
}
