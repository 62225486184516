.container {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  & .switch {
    position: absolute;
    cursor: pointer;
    background-color: var(--switch-unchecked-color);
    border-radius: 53px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;

    &:before {
      position: absolute;
      content: '';
      left: 1px;
      top: 1px;
      width: 22px;
      height: 22px;
      background-color: var(--switch-thumb-color);
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
  }

  & input[type='checkbox'] {
    display: none;
  }

  & input[type='checkbox']:checked + .switch:before {
    transform: translateX(16px);
  }

  & input[type='checkbox']:checked + .switch {
    background-color: var(--switch-checked-color);
  }

  & input[type='checkbox']:disabled + .switch {
    background-color: var(--switch-disabled-color);
    cursor: default;
  }

  & input[type='checkbox']:disabled + .switch:before {
    background-color: var(--switch-thumb-disabled-color);
  }

  &:hover input[type='checkbox']:not([disabled]):not(:checked) + .switch {
    background-color: var(--switch-unchecked-hover-color);
  }

  &:hover input[type='checkbox']:not([disabled]):checked + .switch {
    background-color: var(--switch-checked-color);
  }

  &:active input[type='checkbox']:not([disabled]):not(:checked) + .switch {
    background-color: var(--switch-unchecked-pressed-color);
  }

  &:active input[type='checkbox']:not([disabled]):checked + .switch {
    background-color: var(--switch-checked-color);
  }
}
