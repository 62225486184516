.autoCallbackChkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.error {
    border: 1px solid var(--form-control-error-color);
    padding: 5px;
  }
  && h6 {
    margin: 0;
    font-weight: var(--font-weight-medium);
  }
  && label {
    span {
      padding-left: 10px;
    }
  }
}

.field {
  && label {
    display: flex;
    align-items: center;
  }
}
