.wrapper {
  display: flex;
  gap: var(--spacing-5);
  flex-direction: column;
  justify-content: space-between;
  @media (--tablet) {
    gap: var(--spacing-10);
    flex-direction: row;
    & button {
      align-self: center;
    }
  }
}

.modalBodyClassName {
  && {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    padding-bottom: var(--spacing-5);
    padding-top: var(--spacing-5);

    @media (--tablet) {
      padding-bottom: var(--spacing-8);
      padding-top: var(--spacing-8);
    }
  }
}
