.wrapper {
  display: grid;
  gap: var(--spacing-10);
  & article {
    display: grid;
    gap: var(--spacing-6);
    width: 100%;

    @media (--desktop) {
      max-width: 711px;
    }
    :where(h5) {
      margin: 0;
    }
  }
}

.card {
  background: var(--color-championship-white);
  padding: var(--spacing-5);

  @media (--tablet) {
    padding: var(--spacing-8);
  }

  > form {
    display: grid;
    gap: var(--spacing-4);
  }
  .groupdFields {
    display: grid;
    gap: var(--spacing-4);
    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
      > div:has(input[name='email']) {
        grid-area: 3 / 1 / 3 / 3;
      }
    }

    & button[name='changePasswordButton'] {
      padding: 0;
      cursor: pointer;
    }
  }
}
