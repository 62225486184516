.fieldGroup {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: var(--spacing-5) 0 0;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.field {
  &[data-width='full'] {
    grid-column-start: 1;
    grid-column-end: 2;

    @media (--tablet) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  &[data-width='half'] {
    @media (--tablet) {
      grid-column: auto;
    }
  }
  &[data-width='double'] {
    grid-column-start: 1;
    grid-column-end: 2;
    max-width: calc(50% - 6px);

    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
      max-width: unset;
    }
    @media (--tablet) {
      grid-column-end: 3;
    }
  }
}

.dropDown {
  & ul {
    position: absolute;
    top: 48px;
    max-height: 220px;
  }
}

.textarea {
  &[data-experiment-flag='true'] {
    min-height: auto;
    &:focus {
      min-height: var(--form-textarea-min-height);
    }
  }

  @media (--mobile) {
    padding: 0 !important;
    min-height: 16px !important;
  }
}
