.errorWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-8);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.buttons {
  margin: var(--spacing-8) 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
