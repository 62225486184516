.navItem {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0 var(--spacing-2);
  width: auto;
  height: 40px;
  border: 0;
  background: var(--color-primary-500);
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease, background 0.3s ease;

  & > span {
    width: 100%;
    white-space: nowrap;
  }

  &.isActive,
  &.isCurrentPage,
  &:hover,
  &:focus {
    background: #fff;
    color: var(--color-primary-500);
    transition-delay: 0.1s;
  }

  @media (--desktop) {
    padding: 0 var(--spacing-3);
  }
}

.parentNavItem {
  &[data-is-active='true'] {
    & .collapse {
      opacity: 1;
      pointer-events: all;
      transition-delay: 0.1s;
    }
  }
}

.nav {
  display: none;
  width: 100%;
  height: auto;
  background: var(--color-primary-500);

  & > ul {
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    padding: 0 var(--spacing-8);
    list-style: none;
    max-width: 1440px;

    & > li {
      display: inline;
    }
  }

  & .collapse {
    position: absolute;
    left: 0;
    width: 100%;
    background: var(--color-championship-white);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    transition-behavior: allow-discrete;
  }

  /* Dropdown nav starts here */
  @media (--tablet-l) {
    display: block;
  }

  @media (--desktop-l) {
    & > ul {
      padding: 0 var(--spacing-16);
    }
  }
}

& [data-tenant='rac'] {
  & .nav {
    background: var(--header-nav-background-color);
    border-top: 1px solid var(--color-rac-grey-10, #ededed);
    border-bottom: solid 1px var(--color-primary-500);
  }

  & .navItem {
    background: var(--header-nav-background-color);
    color: var(--color-rac-text-default, #47474a);

    &.isActive,
    &.isCurrentPage,
    &:hover,
    &:focus {
      color: var(--color-primary-600);
    }
  }
}
