.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-3) 0 0;

  & label {
    display: flex;
    width: 100%;
    margin-bottom: var(--spacing-3);
    line-height: 23px;
  }

  :global(.sc-input-checkbox),
  :global(.sc-input-radio) {
    vertical-align: bottom;
    margin-right: var(--spacing-2);
  }

  :global(.sc-input-switch) {
    flex-shrink: 0;
    vertical-align: bottom;
    margin: 0 var(--spacing-1) 0 var(--spacing-3);
    width: 40px;
  }

  &.hasSwitch {
    & label {
      justify-content: space-between;
    }
  }

  &.hasDesc {
    padding: var(--spacing-2) 0;

    & label {
      margin-bottom: var(--spacing-2);
      font-weight: 500;
    }
  }

  &.isDisabled {
    color: var(--color-neutral-300);
  }
}

.desc {
  width: 100%;
  width: calc(100% - var(--spacing-12));
}

.error {
  margin-left: var(--spacing-8);
  width: 100%;
  color: var(--color-error-700);
}
