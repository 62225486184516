.wrapper {
  margin: var(--spacing-12) 0;

  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;

  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -2px;

  @media (--mobile) {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.5px;
  }
}

.variant {
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.5px;
  text-align: left;
  margin: 0 0 var(--spacing-10);

  @media (--mobile) {
    font-size: 16px;
    line-height: 23px;
  }
}

.buttons {
  &[data-desktop-only] {
    @media (--mobile) {
      display: none;
    }
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-2) var(--spacing-6);

  @media (--mobile) {
    flex-direction: column;
    align-items: stretch;

    & button {
      width: 100%;
      max-width: unset;
    }
  }

  & button {
    max-width: 300px;
    & span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-2);
      overflow: hidden;

      & i {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        max-width: 100%;
        font-style: normal;
      }
    }
  }

  & svg {
    font-size: 20px !important;
  }
}

.headings {
  display: flex;
  gap: var(--spacing-3) var(--spacing-4);

  flex-direction: column;

  @media (--mobile) {
    align-items: stretch;
  }
}

.pdfContainer {
  border: 12px solid #f7f7f7;
  margin: var(--spacing-8) 0;

  @media (--mobile) {
    display: none;
  }
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-8);
  & a {
    margin: var(--spacing-8) 0;
  }
}
